import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SEO from 'components/SEO'
import Header from 'components/Header'
import Footer from 'components/Footer'

import s from './styles.module.css'

const Layout = ({
  header,
  backgroundColor,
  children,
  className,
  rootClassName,
}) => {
  return (
    <div
      className={classNames(s.container, rootClassName)}
      style={{ backgroundColor }}
    >
      <SEO />
      <Header type={header} />
      <main className={classNames(s.main, className)}>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  header: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  children: PropTypes.node,
}
Layout.defaultProps = {
  header: '',
  backgroundColor: '',
  className: '',
  rootClassName: '',
  children: null,
}

export default Layout
