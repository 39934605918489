import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import MenuLinks from 'components/MenuLinks'

import s from './styles.module.css'

const Menu = ({ close, visible }) => {
  return (
    <nav
      className={classNames(s.container, { [s.containerVisible]: visible })}
      data-cy="mobile-menu"
    >
      <MenuLinks className={s.mainMenu} linkClassName={s.link} />
      <button
        type="button"
        className={s.closeBtn}
        data-cy="close-menu-btn"
        onClick={close}
      >
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.707 1.293a1 1 0 011.414 0l9.9 9.9a1 1 0 01-1.414 1.414l-9.9-9.9a1 1 0 010-1.414z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.343 12.707a1 1 0 010-1.414l9.9-9.9a1 1 0 111.414 1.415l-9.9 9.9a1 1 0 01-1.414 0z"
            fill="#fff"
          />
        </svg>
      </button>
    </nav>
  )
}

Menu.propTypes = {
  close: PropTypes.func,
  visible: PropTypes.bool,
}
Menu.defaultProps = {
  close: () => {},
  visible: false,
}

export default Menu
