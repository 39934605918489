import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

// import { INSTAGRAM_URL, FACEBOOK_URL } from 'constants'

import LinkExternal from 'components/LinkExternal'

import s from './styles.module.css'

const Footer = () => {
  const renderIcon = (classname) => {
    return (
      <button type="button" className={classNames(s.icon, classname)}>
        <svg
          width="18"
          height="19"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.234 9.093a8.5 8.5 0 001.28-1.079.74.74 0 00-.29-1.212.782.782 0 00-.828.19c-.25.253-.521.485-.81.695L8.225.417a.8.8 0 00-.298-.305.824.824 0 00-1.129.305L3.411 7.692a6.113 6.113 0 01-.794-.7.791.791 0 00-1.045-.082.75.75 0 00-.293.527.731.731 0 00.198.567c.39.405.823.768 1.291 1.084L.071 14.926a.742.742 0 00.053.729.787.787 0 00.658.345c.15 0 .296-.043.42-.123a.76.76 0 00.286-.327l2.65-5.71a8.47 8.47 0 006.726 0l2.645 5.71c.06.134.16.247.285.327a.786.786 0 00.74.056.779.779 0 00.416-.43.747.747 0 00-.024-.592l-2.692-5.818zM7.53 9.042a6.86 6.86 0 01-2.739-.563l2.707-5.905 2.734 5.905a6.828 6.828 0 01-2.728.563h.026z"
            fill="#595959"
          />
        </svg>
      </button>
    )
  }

  return (
    <div className={s.container}>
      {renderIcon(s.iconDesktop)}
      <div className={s.linksContainer}>
        <p className={s.linksHeading}>Wholesale Accounts</p>
        <div className={s.links}>
          <LinkExternal
            link="https://chalkuk.com/wholesale/application"
            label="Signup"
            className={s.linkLeft}
          />
          <LinkExternal
            link="https://wholesale.chalkuk.com/account"
            label="Login"
            className={s.linkRight}
          />
        </div>
      </div>
      <nav className={s.menuMobile}>
        <div className={s.menuSection}>
          <Link to="/" className={s.link}>
            Home
          </Link>
          <Link to="/about" className={s.link}>
            About
          </Link>
          <Link to="/our-collection" className={s.link}>
            Collection
          </Link>
          <Link to="/boutiques" className={s.link}>
            Boutiques
          </Link>
        </div>
        <div className={s.menuSection}>
          <Link to="/wholesale" className={s.link}>
            Wholesale
          </Link>
          <Link to="/faqs" className={s.link}>
            FAQs
          </Link>
          <Link to="/contact" className={s.link}>
            Contact
          </Link>
          <Link to="/legal/terms-conditions" className={s.link}>
            Legal
          </Link>
        </div>
        <div className={s.menuSection}>
          {/* <LinkExternal
            link={INSTAGRAM_URL}
            label="Instagram"
            className={s.link}
          /> */}
          <LinkExternal
            link="https://www.instagram.com/chalkuk"
            label="Instagram"
            className={s.link}
          />
          {/* <LinkExternal
            link={FACEBOOK_URL}
            label="Facebook"
            className={s.link}
          /> */}
          <LinkExternal
            link="https://www.facebook.com/Chalk-574040983050940"
            label="Facebook"
            className={s.link}
          />
        </div>
        {renderIcon(s.iconMobile)}
      </nav>
      <div className={s.legalContainer}>
        <div className={s.legal}>
          <p>&copy; Chalk UK {new Date().getFullYear()}</p>
          <div className={s.menuDesktop}>
            <Link to="/wholesale" className={s.link}>
              Wholesale
            </Link>
            <Link to="/shows" className={s.link}>
              Shows
            </Link>
            <Link to="/contact" className={s.link}>
              Contact
            </Link>
            <Link to="/faqs" className={s.link}>
              FAQs
            </Link>
            <Link to="/legal/terms-conditions" className={s.link}>
              Terms & Conditions
            </Link>
            {/* <LinkExternal
              link={INSTAGRAM_URL}
              label="Instagram"
              className={s.link}
            /> */}
            <LinkExternal
              link="https://www.instagram.com/chalkuk"
              label="Instagram"
              className={s.link}
            />
            {/* <LinkExternal
              link={FACEBOOK_URL}
              label="Facebook"
              className={s.link}
            /> */}
            <LinkExternal
              link="https://www.facebook.com/Chalk-574040983050940"
              label="Facebook"
              className={s.link}
            />
          </div>
          <LinkExternal link="https://patron.studio" label="Site by Patron" />
        </div>
      </div>
    </div>
  )
}

export default Footer
